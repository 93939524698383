<template>
    <b-overlay :show="!mainLoaded" rounded="sm" :class="'overlayed brand_'+brand_slug">
        <template #overlay>
            <div class="text-center">
                <Overlay></Overlay>
            </div>
        </template>
        <div id="app" :data-route="this.$route.name">
            <Nav></Nav>
            <div id="content" class="container mt-4">
                <main>
                    <transition name="slide-fade">
                        <router-view></router-view>
                    </transition>
                </main>
            </div>
            <div class="d-block mt-4 pt-4"></div>

        </div>
    </b-overlay>
</template>
<script>
import BrandConfig from "./js/BrandConfig";
import Nav from "./js/components/Nav";
import {BOverlay} from "bootstrap-vue";
import Overlay from "./js/components/Overlay";
import $ from "jquery";
import LocaleService from "./js/service/LocaleService";
import GtmService from "./js/service/GtmService";

require("jquery.cookie");

const name = 'lingy_panel_cookie';

export default {
    components: {Overlay, Nav, BOverlay},
    directives: {
        'b-overlay': BOverlay,
    },
    data() {
        return {
            mainLoaded: false,
            brand_slug: BrandConfig.brandName
        }
    },
    updated() {
        if (sessionStorage.getItem("isLogged")) {
            $('body').addClass('authorized');
        } else {
            $('body').removeClass('authorized');
        }
    },
    watch: {
        $route(to, from) {
            const changedLocale = to.params.locale;
            if (from.name === null && changedLocale !== undefined && this.$store.getters["common/isAuthorized"]) {
                const customerLocale = this.$store.getters["authorized/customer"].data.locale;
                if (customerLocale !== changedLocale) {
                    const localeService = new LocaleService(this.$store);
                    localeService.updateCustomerLocale(changedLocale).then(()=>{
                        this.$store.dispatch("authorized/customer");
                        this.$root.$emit('locale-customer-changed', changedLocale);

                    });
                }
            }
        }
    },
    mounted() {
        let handle = setInterval(() => {
            if (window.loaded) {
                $('footer').removeAttr('hidden');
                this.mainLoaded = true;
                clearInterval(handle);
            }
        }, 200);
        this.$root.$on("locale-changed", () => {
            this.$store.dispatch("common/countries").then(() => {
                this.$root.$emit("countries-changed");
            });
            this.$store.dispatch("common/categories");
        });

        if (process.env.VUE_APP_BRAND_GTAG) {
            GtmService.watch(this.$root, gtag);
        }
    },
    methods: {
        redirect() {
            this.$router.push({name: 'login'});
        }
    }
}
</script>
