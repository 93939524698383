import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import App from './../resources/brands/default/App.vue'
import './registerServiceWorker'
import BrandConfig from "../resources/brands/default/js/BrandConfig";
import i18n from "../resources/brands/common/js/i18n";
import store from "../resources/brands/default/js/store/Store";
import router from "../resources/brands/default/js/router";
import axios from "axios";
import Storage from "../resources/brands/default/js/store/Storage";
import * as Sentry from "@sentry/vue";
import {Integrations} from "@sentry/tracing";
import Helpers from "../resources/brands/default/js/helpers/Helpers";


const
    isDevelopment = process.env.NODE_ENV == "development",
    Vuelidate = require("vuelidate"),
    brand_slug = BrandConfig.brandName;

if (!isDevelopment) {
    Sentry.init({
        Vue,
        environment: process.env.NODE_ENV,
        release: process.env.VUE_APP_CI_COMMIT_REF_SLUG,
        dsn: process.env.VUE_APP_BRAND_SENTRY_DSN,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["localhost", process.env.VUE_APP_URL!, /^\//],
            }),
        ],
        tracesSampleRate: 1.0,
    });
}

require('vue/dist/vue.min')
require('bootstrap-vue/dist/bootstrap-vue')
require('bootstrap-vue/dist/bootstrap-vue-icons')

if(process.env.VUE_APP_PANEL_BRAND == 'dogadamycie_pl') {
    require('../resources/brands/default/assets/app-dc.scss')
} else {
    require('../resources/brands/default/assets/app-lingy.scss')
}


declare global {
    interface Window {
        loaded: any;
        dataLayer: any;
    }
}

Vue.use(Vuelidate);
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)


Vue.config.devtools = isDevelopment;
Vue.config.productionTip = !isDevelopment;
axios.interceptors.request.use(function (request) {
    request.headers["x-brand"] = btoa(BrandConfig.brandName!).replace("==", "");
    request.headers["Accept-Language"] = Storage.getItem('locale') || BrandConfig.default_locale[brand_slug as keyof typeof BrandConfig.default_locale];
    return request;
});

axios.interceptors.response.use(
    function (response) {
        try {
            const data = response.data.split(".")[1];
            return {
                data: JSON.parse(atob(data)),
            };
        } catch (e) {
            return response.data;
        }
    },
    (error) => {
        const respText = error.response && error.response.data.error
            ? error.response.data.error
            : false;

        if(error.response.status === 500 && (error.response.data == '' || error.response.hasOwnProperty('data')) && error.response.config.hasOwnProperty('data') && error.response.config.headers.Authorization){
            Helpers.flushAuth(store).then(() => {
                store.dispatch("common/logout");
                window.location.href = "/";
            });
        }

        if (error.response && 401 === error.response.status && respText != "InvalidCredentials") {
            Helpers.flushAuth(store).then(() => {
                window.location.href = "/";
            });
        } else {
            return Promise.reject(error);
        }
    }
);


window.loaded = false;
router.beforeEach((to, from, next) => {
    if (store.getters["common/isAuthorized"]
        && store.getters["common/brandMetas"]
        && store.getters["common/currencies"]
        && store.getters["common/categories"]) {
        window.loaded = true;
        return next();
    } else {
        store.dispatch("common/auth").then(() => {
            store.dispatch("common/brandMetas").then(() => {
                store.dispatch("common/currencies").then(() => {
                    store.dispatch("common/categories").then(() => {
                        window.loaded = true;
                        return next();
                    })
                })
            })
        })
    }

});

router.afterEach((to, from) => {
    let link = document.querySelector("link[rel='canonical']") || document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', location.protocol + '//' + location.host + location.pathname);
    document.head.appendChild(link);
});

// @ts-ignore
new Vue({
    i18n,
    router,
    store, // @ts-ignore
    render: h => h(App)
}).$mount('#app')
